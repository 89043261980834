import {
  createSlice,
  createAsyncThunk,
  combineReducers,
} from "@reduxjs/toolkit";
import { executeAPICall } from "../executeAPICall";

import config from "config";
import {
  getOrganizationId,
  getOrganizationSwithId,
  getToken,
} from "utils/session_storage";

const initialState = {
  data: {},
  error: {},
  isLoading: false,
};

export const getAllClientListAPI = createAsyncThunk(
  "GET/GET-CLIENTS-LIST",
  async () => {
    return executeAPICall({
      method: "GET",
      url: `/scanner/clients/get-all-client-details/${getOrganizationSwithId()}/client`,
      baseURL: config.BASE_URL,
    });
  }
);

const getClientsDataSlice = createSlice({
  name: "getAllClientsData",
  initialState,
  reducers: {
    resetGetAllClientListAPI: (state) => {
      state.isLoading = false;
      state.data = {};
      state.error = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllClientListAPI.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllClientListAPI.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.error = {};
    });
    builder.addCase(getAllClientListAPI.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isLoading = false;
      state.data = {};
      state.error =
        action.payload !== undefined
          ? action.payload
          : "Oops, Requested data was not found";
    });
  },
});

export const addClientAPI = createAsyncThunk("ADD/CLIENT", async (data) => {
  return executeAPICall({
    method: "POST",
    url: `/scanner/clients/add-client-details`,
    baseURL: config.BASE_URL,
    reqData: data,
  });
});

const addClientSlice = createSlice({
  name: "addClient",
  initialState,
  reducers: {
    resetAddClient: (state, action) => {
      state.isLoading = false;
      state.data = {};
      state.error = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addClientAPI.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addClientAPI.fulfilled, (state, action) => {
      state.isLoading = false;
      // state.data = action.payload;
      state.data = {
        message: "Client deleted successfully",
      };
      state.error = {};
    });
    builder.addCase(addClientAPI.rejected, (state, action) => {
      state.isLoading = false;
      state.data = {};
      // state.error = action.payload;
      state.error = {
        message: "Client is not added",
      };
    });
  },
});

export const editClientAPI = createAsyncThunk("EDIT/CLIENT", async (data) => {
  return executeAPICall({
    method: "PUT",
    url: `/scanner/clients/edit-client-details`,
    baseURL: config.BASE_URL,
    reqData: data,
  });
});

const editClientSlice = createSlice({
  name: "editClient",
  initialState,
  reducers: {
    resetEditClient: (state, action) => {
      state.isLoading = false;
      state.data = {};
      state.error = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(editClientAPI.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editClientAPI.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.error = {};
    });
    builder.addCase(editClientAPI.rejected, (state, action) => {
      state.isLoading = false;
      state.data = {};
      state.error = action.payload;
    });
  },
});

export const deleteClientAPI = createAsyncThunk(
  "DELTE/CLIENT",
  async (data) => {
    console.log("DATA", data);
    return executeAPICall({
      method: "POST",
      url: `/scanner/clients/delete-client-details`,
      baseURL: config.BASE_URL,
      reqData: data,
    });
  }
);

const deleteClientSlice = createSlice({
  name: "deleteClient",
  initialState,
  reducers: {
    resetDeleteClient: (state, action) => {
      state.isLoading = false;
      state.data = {};
      state.error = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(deleteClientAPI.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteClientAPI.fulfilled, (state, action) => {
      state.isLoading = false;
      // state.data = action.payload;
      state.data = {
        message: "Client deleted successfully",
      };
      state.error = {};
    });
    builder.addCase(deleteClientAPI.rejected, (state, action) => {
      state.isLoading = false;
      state.data = {};
      // state.error = action.payload;
      state.error = {
        message: "Client is not deleted",
      };
    });
  },
});

export const { resetGetAllClientListAPI } = getClientsDataSlice.actions;
export const { resetAddClient } = addClientSlice.actions;
export const { resetEditClient } = editClientSlice.actions;
export const { resetDeleteClient } = deleteClientSlice.actions;

const clientAPI = combineReducers({
  getAllClients: getClientsDataSlice.reducer,
  postClients: addClientSlice.reducer,
  editClients: editClientSlice.reducer,
  deleteClients: deleteClientSlice.reducer,
});

export default clientAPI;
