// console.log("window.location.href", window.location.href);
// const config = {
//     ENVIRONMENT: process.env.NODE_ENV,
//     BASE_URL: process.env.REACT_APP_API_ENDPOINT
// }
let config = {
  BASE_URL: "",
};

if (window.location.href.indexOf("netpropriate.proximabiz") > -1) {
  config.BASE_URL = "https://api.netpropriate.net";
}
if (window.location.href.indexOf("stg.netpropriate") > -1) {
  config.BASE_URL = "https://stg.netpropriate.net";
}

if (window.location.href.indexOf("localhost") > -1) {
  config.BASE_URL = "https://stg.netpropriate.net";
}

if (window.location.href.indexOf("184.105.229.173") > -1) {
  config.BASE_URL = "http://184.105.229.173";
}

if (window.location.href.indexOf("184.105.229.172") > -1) {
  config.BASE_URL = "http://184.105.229.172";
}

if (window.location.href.indexOf("184.105.229.171") > -1) {
  config.BASE_URL = "http://184.105.229.171";
}
if (window.location.href.indexOf("184.105.229.174") > -1) {
  config.BASE_URL = "http://184.105.229.174";
}
if (window.location.href.indexOf("184.105.229.175") > -1) {
  config.BASE_URL = "http://184.105.229.175";
}

// console.log(config);

export default config;
