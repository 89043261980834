import { configureStore } from "@reduxjs/toolkit";
import {
  createStateSyncMiddleware,
  initMessageListener,
} from "redux-state-sync";
import instruction from "./SliceFeatures/instructionSlice";
import homepage from "REDUX/SliceFeatures/homepageSlice";
import deleteNetworkHost from "./SliceFeatures/deleteNetworkHostSlice";
import organizations from "./SliceFeatures/organizationSlice";
import storeDelHostNet from "./SliceFeatures/storedDeletedNetworkHostSlice";
import networkAPI from "./SliceFeatures/networkPageSlice";
import hostListGetData from "./SliceFeatures/hostPageListSlice";
import generateAgent from "./SliceFeatures/generateAgentSlice";
import profilePage from "./SliceFeatures/profilePageSlice";
import AllLicenseAPIPage from "./SliceFeatures/AllLicenseSlice";
import AllDevicesAPIPage from "./SliceFeatures/AllDevicesSlice";
import clientAPI from "./SliceFeatures/ClientSlice";

const store = configureStore({
  reducer: {
    getInstrucitonDataList: instruction,
    homepage: homepage,
    deleteNetworkHost: deleteNetworkHost,
    storeDelHostNet: storeDelHostNet,
    organizations: organizations,
    networkPageListAPI: networkAPI,
    hostListGetData: hostListGetData,
    generateAgent: generateAgent,
    profilePage: profilePage,
    AllLicenseAPIPage: AllLicenseAPIPage,
    AllDevicesAPIPage: AllDevicesAPIPage,
    clientAPI: clientAPI,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(createStateSyncMiddleware({})),
});

initMessageListener(store);
export default store;
